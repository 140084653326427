a {
    text-decoration: none!important;
}

#banner, .formInput, img, #myInput, .navbar, .footer, .width100 {
    width: 100%!important;
}

.bg-theme {
    background-color: #C32!important;
}

.bg-nugget {
    background-image: url("../../public/assets/images/Background5.png")!important;
    background-size: 100% auto;
    background-repeat: repeat;
}

body { 
    background-color: #DDD!important;
}

.border-none {
    border: none!important;
}

.btn-block {
    display: block!important;
    width: 100%!important;
    min-height: 100%!important;
}

.btn:focus {
    box-shadow: none!important;
}

.btn-theme {
    color: #fff!important;
    background-color: #D43!important;
    border: none!important;
}

.btn-theme:hover, .btn-theme:focus {
    color: #fff!important;
    background-color: #A21!important;
    border: none!important;
}

.btn-small {
    color: #000!important;
    background-color: #ccc!important;
    border: 0.1rem solid!important;
    border-color: #ccc!important;
    font-size: 0.7rem!important;
}

.btn-small:hover, .btn-small:focus {
    color: #000!important;
    background-color: #aaa!important;
    border: 0.1rem solid!important;
    border-color: #ccc!important;
}

.btn-smallun {
    color: #fff!important;
    background-color: #444!important;
    border: 0.1rem solid!important;
    border-color: #ccc!important;
    font-size: 0.7rem!important;
}

.btn-smallun:hover, .btn-smallun:focus {
    color: #fff!important;
    background-color: #222!important;
    border: 0.1rem solid!important;
    border-color: #ccc!important;
}

.btn-weak {
    color: #000!important;
    background-color: #fff!important;
    border: 0.1rem solid!important;
    border-color: #ccc!important;
}

.btn-weak:hover {
    color: #000!important;
    background-color: #eee!important;
    border: 0.1rem solid!important;
    border-color: #ccc!important;
}

.card, .modal-content, #quote-page {
    background-color: #fff!important;
    color: #000!important;
}

.card-title {
    font-weight: 600!important;
}

.carousel-control-prev, .carousel-control-next {
    top: 1rem!important;
    bottom: auto!important;
}

.censor {
    background-color: rgb(193, 125, 0)!important;
    text-shadow: none!important;
    color: rgb(193, 125, 0)!important;
    transition: all 0.4s ease!important;
}

.censor:hover, .censor:active, .censor:focus {
    background-color: transparent!important;
    color: white!important;
    transition: all 0.7s ease!important;
}

.col-05 {
    flex: 0 0 auto!important;
    width: 4%!important;
}

.cool-share-button {
    font-size: 1.8rem!important;
    cursor: pointer;
}

.floating-side-button-left, .floating-side-button-right {
    font-size: 3rem!important;
    opacity: 0.1!important;
    top:5%!important;
    position: absolute!important;
}

.floating-side-button-left:hover, .floating-side-button-right:hover {
    opacity: 0.6!important;
    transition: all 0.5s ease!important;
}

.floating-side-button-left, .float-left {
    float: left!important;
}

.floating-side-button-right {
    left: 85%!important;
    float: right!important;
}

.fitter {
    background: rgba(40,40,40,0.4)!important;
    border-radius: 25px!important;
    position: sticky!important;
    top: 13vh!important;
    max-width: 26px!important;
    max-height: 90vh!important;
    min-height: 74vh!important;
}

.font-poppins {
    font-family: 'Poppins', sans-serif!important;
}

.font-libre {
    font-family: 'Frank Ruhl Libre', serif;
}

.font-noto {
    font-family: 'Noto Serif Vithkuqi', serif;
}

.font-victor-mono {
    font-family: 'Victor Mono', monospace;
}

#footer-img {
    width: 70%!important;
}

.link-theme, .text-theme {
    color: #77A!important;
    text-decoration-color: #77A!important;
    /* color: #494; */
}

.link-theme:hover {
    color: #99C!important;
    text-decoration-color: #99C!important;
}

.link-weak, .quote-card .link-theme {
    color: rgb(248, 247, 247)!important;
}

.link-weak:hover, .quote-card .linktheme:hover {
    color: #ccc!important;
}

.link-related {
    color: rgb(66, 131, 84)!important;
    text-decoration-color: rgb(66, 131, 84)!important;
}

.link-related:hover {
    color: rgb(132, 205, 152)!important;
    text-decoration-color: rgb(132, 205, 152)!important;
}

.link-unrelated {
    color: rgb(158, 54, 54)!important;
    text-decoration-color: rgb(158, 54, 54)!important;
}

.link-unrelated:hover {
    color: rgb(220, 147, 147)!important;
    text-decoration-color: rgb(220, 147, 147)!important;
}

.mainBody {
    min-height: 67vh!important;
}

.modal-dialog {
    margin-left: auto!important;
    margin-right: auto!important;
}

.navbar-brand {
    max-width: 15%!important;
}

.navbar-toggler:focus, .btn-light:focus, .btn-check:focus, .btn-primary:focus, .btn-weak:focus {
    box-shadow: none!important;
}

.navPage-body, .max-width-100 {
    max-width: 100%
}

.hoverable {
    border-top: 0.1rem solid!important;
    border-bottom: 0.1rem solid!important;
    border-color: #333!important;
}

.hoverable:hover {
    border-color: white!important;
    transition: all 0.7s ease!important;
}

html {
    min-height:100%;
}

.quote-body, .quote-preview {
    font-weight: 500!important;
}

#quote-page {
    border-radius:0.3rem 0.3rem 0rem 0rem;
}

#quote-page, .quote-card {
    background-color: black!important;
    color: white!important;
    background-image: url("../assets/images/N_Icon2_TransparentLessDark.png")!important;
    background-size: contain!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
}

.smaller-text {
    font-size: 14px!important;
    font-weight: 650!important;
}

.share-button {
    font-size: 1.8rem!important;
    transition: all 0.4s ease!important;
}

#share-twitter {
    color: #1DA1F2 !important;
}

#share-twitter:hover {
    color: #0d476b !important;
}

#share-truth, .share-truth {
    color: #5346ED !important;
}

#share-truth:hover, .share-truth:hover {
    color: #2316AD !important;
}

#share-truth img, .share-truth img {
    max-width:32px;
}

#share-facebook, .share-facebook {
    color: #4867AA !important;
}

#share-facebook:hover, .share-facebook:hover {
    color: #1c315e !important;
}

#share-X, .share-X {
    color: #DDD !important;
}

#share-X:hover, .share-X:hover {
    color: #999 !important;
}

.sidebar-text {
    font-size: 0.8rem!important;
}

.subtext {
    color: #777!important;
    font-size: 14px!important;
}

.width80 .modal-dialog {
    max-width: 90%!important;
}

.quote-card h2, .quote-modal-text {
    font-size: 1.5rem;
}

.quote-modal-text, .quote-card .card-text, .quote-card .link-theme, .censor:hover {
    text-shadow: 0 0 #000, 0 0 10px #000!important;
}
